import React from 'react';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Breadcrumb from '../components/Breadcrumb';
import CoinPaymentSection from '../components/Payment/CoinPayment';
import CoinPaymentSolutions from '../components/Payment/CoinPayment/CoinPaymentSolutions';
import HostingActionTwo from '../components/HostingActionTwo';


const CoinPayment = () => {
    return (
        <div>
            <Breadcrumb
                breadcrumbClass="breadcrumb_area"
                imgName="breadcrumb/banner_bg.png"
                Ptitle="Crypto Currency Payments"
                Pdescription="" />
            <CoinPaymentSection/>
            <CoinPaymentSolutions/>
            <HostingActionTwo/>
            <FooterTwo FooterData={FooterData} />
        </div>
    )
}
export default CoinPayment;