import React from 'react';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import JobsHiringContent from './../components/Jobs/JobsHiringContent'
import Breadcrumb from '../components/Breadcrumb';

const JobsHiring = () => {
	return (
		<div>
			<Breadcrumb
				breadcrumbClass="breadcrumb_area"
				imgName="breadcrumb/banner_bg.png"
				Ptitle="Career Openings"
				Pdescription="Hey, We're hiring!" />
			<JobsHiringContent/>
			<FooterTwo FooterData={FooterData} />
		</div>
	)
}
export default JobsHiring;
