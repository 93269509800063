import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import SupportContent from '../components/Support/SupportContent';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const Support = () => {
    return(
        <div>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Help & Support" Pdescription=""/>
            <SupportContent/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}

export default Support;