import React, {Component} from 'react';
import Plx from "react-plx";
import Reveal from "react-reveal"

class AppFeaturesTwo extends Component{
    render(){
        const exampleParallaxData = [
              {
                start: 700,
                duration: 100,
                properties: [
                  {
                    startValue: -100,
                    endValue: -20,
                    property: "translateY"
                  },
                  {
                    startValue: 1,
                    endValue: 20,
                    property: "translateX"
                  },
                  {
                    startValue: 0,
                    endValue: 20,
                    property: "translate"
                  },
                  {
                    startValue: 0,
                    endValue: 2,
                    property: "translateY"
                  }
                ]
              }
          ];
          const exampleParallaxDatas = [
              {
                start: 700,
                duration: 100,
                properties: [
                  {
                    startValue: 100,
                    endValue: 30,
                    property: "translateY"
                  },
                  {
                    startValue: 1.5,
                    endValue: -20,
                    property: "translateX"
                  },
                  {
                    startValue: 0,
                    endValue: 20,
                    property: "translate"
                  },
                  {
                    startValue: 0,
                    endValue: 5,
                    property: "translateY"
                  }
                ]
              }
          ];
        return(
            <section className="app_featured_area" id="features">
                <div className="container">
                <div className="hosting_title security_title text-center">
 <h2 className="wow fadeInUp" data-wow-delay="0.2s"><span className="f_size_50 mb-3">Why Choose Everpay</span>Thousands of businesses trust us</h2>
                </div>
                    <div className="row flex-row-reverse app_feature_info">
                        <div className="col-lg-6">
                            <div className="app_fetured_item">
                                <Plx className="app_item item_one" parallaxData={exampleParallaxData}>
                                    <i className="ti-credit-card f_size_40 w_color"></i>
                                    <h6 className="f_p f_400 f_size_16 w_color l_height45">Global Payments</h6>
                                </Plx>
                                <Plx className="app_item item_two"  parallaxData={exampleParallaxDatas}>
                                    <i className="ti-receipt f_size_40 w_color"></i>
                                    <h6 className="f_p f_400 f_size_16 w_color l_height45">Transparent Pricing</h6>
                                </Plx>
                                <div className="app_item item_three" data-parallax='{"x": 50, "y": 10}'>
                                    <i className="ti-shopping-cart f_size_40 w_color"></i>
         <h6 className="f_p f_400 f_size_16 w_color l_height45">Services & Solutions</h6>
                                </div>
                                <div className="app_item item_four" data-parallax='{"x": -20, "y": 50}'>
                                    <i className="ti-tablet f_size_40 w_color"></i>
        <h6 className="f_p f_400 f_size_16 w_color l_height45">Security & Compliance</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                          <Reveal bottom cascade>
                              <div className="app_featured_content">
                                <h2 className="f_p f_size_30 f_700 t_color3 l_height45 pr_70 mb-30">Payment solutions to maximize your business</h2>
                                <p className="f_400">No matter how you decide to make money online or face to face, you'll need one thing: a reliable, safe, user-friendly payment solution. Everpay is the next-gen distributed commerce platform that makes it easy for you to streamline all your payment channels and maximize revenue for any type business model.</p>
                                <a href="/industries-served" className="btn_get btn_get_two btn_hover text-center mt_60 mb_70"> See Why </a>
                              </div>
                          </Reveal>
                            
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}
export default AppFeaturesTwo;