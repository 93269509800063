import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Sticky from 'react-stickynode';
import FooterData from './Footer/FooterData';

class CustomNavbar extends Component {
    render() {
        var { mClass, nClass, cClass, slogo, hbtnClass } = this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
     <div className="container">
                    <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                        <div className={`container-fluid ${cClass}`}>
                            <Link className={`navbar-brand ${slogo}`} to="/">
                                <img className="logo1" src="https://res.cloudinary.com/lmj6rf6tz/image/upload/c_scale,w_135/v1604178445/White_logo_HD_xcpbgf.png"  width="135" alt="Everpay" />
                                <img  className="logo2" src="https://res.cloudinary.com/lmj6rf6tz/image/upload/c_scale,w_135/v1574986847/img/logo-new.png" width="135" alt="Everpay" />
</Link>
                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="menu_toggle">
                                    <span className="hamburger">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                    <span className="hamburger-cross">
                                        <span></span>
                                        <span></span>
                                    </span>
                                </span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                    <li className="dropdown submenu nav-item">
                                        <Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Why Us</Link>
                                        <ul role="menu" className=" dropdown-menu">
                                            {FooterData.whyUs.map((item) => {
                                                return (
                                                    <li className="nav-item"><NavLink exact title={item.text} className="nav-link" to={item.url}>{item.text}</NavLink></li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                    <li className="dropdown submenu nav-item">
                                        <Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">What We Do</Link>
                                        <ul role="menu" className=" dropdown-menu">
                                            {FooterData.whatWedo.map((item) => {
                                                return (
                                                    <li className="nav-item"><NavLink exact title={item.text} className="nav-link" to={item.url}>{item.text}</NavLink></li>
                                                )
                                            })}
                                        </ul>
                                    </li>

<li className="dropdown submenu nav-item"><Link title="Shop" className="dropdown-toggle nav-link" to="https://shop.everpayinc.com" >Shop</Link>
</li>
                                    <li className="dropdown submenu nav-item">
                                        <Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Company</Link>
                                        <ul role="menu" className="dropdown-menu">
                                            {FooterData.theCompany.map((item) => {
                                                return (
                                                    <li className="nav-item"><NavLink exact title={item.text} className="nav-link" to={item.url}>{item.text}</NavLink></li>
                                                )
                                            })}
                                        </ul>
                                    </li>

                                    <li className="dropdown submenu nav-item"><Link title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" to="#">Resources</Link>
                                        <ul role="menu" className=" dropdown-menu">
                                            {FooterData.resources.map((item) => {
                                                return (
                                                    <li className="nav-item">
<NavLink exact title={item.text} className="nav-link" to={item.url}>{item.text}</NavLink>
</li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                </ul>

 <a className={`btn_six btn_get_two btn_hover ${hbtnClass}`} href="https://gate.everpayinc.com/login">Login <i className="ti-arrow-right"></i></a>

    <a className={`btn_get btn_hover ${hbtnClass}`} href="https://gate.everpayinc.com/register">Get Started</a>

                            </div>
                        </div>
                    </nav>
                  </div>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;
