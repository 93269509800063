import React, { Component } from 'react';
// import Slider from 'react-slick';

class TermsContent extends Component {
    render() {
        return (
            <section className="faq_area bg_color sec_pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab-content faq_content" id="myTabContent">
                                <div className="tab-pane fade show active" id="purchas" role="tabpanel" aria-labelledby="purchas-tab">
                                    <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Last Updated: 29/10/2020</h3>
                                    <div className="card-body">
                                        <p>
                                            PLEASE READ THESE SITE TERMS CAREFULLY. BY ACCESSING OR USING OUR WEBSITE, YOU AGREE TO BE BOUND BY THESE SITE TERMS AND ALL TERMS AND GUIDELINES INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT ACCESS OR USE OUR WEBSITE.
                                        </p>
                                        <p>
                                            These Site Terms ("Terms") apply solely to your access to, and use of, any website of Everpay ("Everpay"), including https://everpayinc.com ("Site"). These Terms do not alter the terms or conditions of any other agreement you may have with Everpay, or its subsidiaries or affiliates, for products, services or otherwise. If you are using the Site on behalf of any entity, you represent and warrant that you are authorized to accept these Terms on such entity's behalf and that such entity will be responsible to Everpay if you violate these Terms.
                                        </p>
                                        <p>
                                            Everpay reserves the right to change or modify the terms and conditions contained in the Terms or any policy or guideline of the Site, at any time and in its sole discretion. Any changes or modifications will be effective immediately upon posting of the revisions to the Site, and you waive any right you may have to receive specific notice of such changes or modifications. Your continued use of the Site following the posting of changes or modifications will confirm your acceptance of such changes or modifications; therefore, you should frequently review these Terms and all applicable terms and policies to understand the terms and conditions that apply to your use. If you do not agree to the amended terms, you must stop using the Site. Any use of the Site in violation of these Terms may result in, among other things, termination or suspension of your right to use the Site.
                                        </p>
                                    </div>
                                    <p>If you have any questions or comments regarding the use of the Site, please contact <a href="mailto:support@everpayinc.com">support@everpayinc.com</a>.</p>

                                    <h6 className="f_p f_size_22 f_500 t_color3 mb_20">1. Terms of Service</h6>
                                    <div className="card-body">
                                        <p>Your registration for, and use of, Everpay's products, services or other features, technologies and/or functionalities, whether offered through the Site or any third party website or application, ("Everpay Services") are governed by the Terms of Service. If you do not agree to the Terms of Service, do not use or access the Everpay Services.</p>
                                    </div>

                                    <h6 className="f_p f_size_22 f_500 t_color3 mb_20">2. Privacy Policy</h6>
                                    <div className="card-body">
                                        <p>Please refer to our Privacy Policy for information about how Everpay collects, uses and discloses personally identifiable information from its users.</p>
                                    </div>


                                    <h6 className="f_p f_size_22 f_500 t_color3 mb_20">3. Copyright and Limited License</h6>
                                    <div className="card-body">
                                        <p>Unless otherwise indicated, the Site and all content and other materials on the Site, including, without limitation, the Everpay logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof (collectively, "Site Materials") are the proprietary property of Everpay or its licensors or users and are protected by U.S. and international copyright laws.</p>
                                        <p>You are granted a limited, non-sublicensable license to access and use the Site and Site Materials; however, such license is subject to these Terms and does not include: (a) any resale or commercial use of the Site or Site Materials; (b) the distribution, public performance or public display of any Site Materials; (c) modifying or otherwise making any derivative uses of the Site and the Site Materials, or any portion thereof; (d) use of any data mining, robots or similar data gathering or extraction methods; (e) downloading (other than the page caching) of any portion of the Site or the Site Materials or any information contained therein, except as expressly permitted on the Site; or (f) any use of the Site or the Site Materials other than for their intended purposes. Any use of the Site or the Site Materials other than as specifically authorized herein, without the prior written permission of Everpay, is strictly prohibited and will terminate the license granted herein. Such unauthorized use may also violate applicable laws including, without limitation, copyright and trademark laws and applicable communications regulations and statutes. Unless explicitly stated herein, nothing in these Terms shall be construed as conferring any license to intellectual property rights, whether by estoppel, implication or otherwise. This license is revocable at any time.</p>
                                    </div>

                                    <h6 className="f_p f_size_22 f_500 t_color3">4. Trademarks</h6>
                                    <div className="card-body">
                                        <p>"Everpay," "Everpayinc.com," the Everpay logos and any other product or service name or slogan contained in the Site are trademarks of Everpay and its suppliers or licensors and may not be copied, imitated or used, in whole or in part, without the prior written permission of Everpay or the applicable trademark holder. You may not use any metatags or any other "hidden text" utilizing "Everpay" or any other name, trademark or product or service name of Everpay without our prior written permission. In addition, the look and feel of the Site, including all page headers, custom graphics, button icons and scripts, is the service mark, trademark and/or trade dress of Everpay and may not be copied, imitated or used, in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product names and Everpay names or logos mentioned in the Site are the property of their respective owners. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by us.</p>
                                    </div>


                                    <h6 className="f_p f_size_22 f_500 t_color3">5. Hyperlinks</h6>
                                    <div className="card-body">
                                        <p>
                                            You are granted a limited, non-exclusive right to create a text hyperlink to the Site for noncommercial purposes, provided such link does not portray Everpay or any of its products and services in a false, misleading, derogatory or otherwise defamatory manner and provided further that the linking site does not promote illegal gambling or contain any obscene, pornographic, sexually explicit or illegal material or any material that is offensive, harassing or otherwise objectionable. This limited right may be revoked at any time. You may not use a Everpay logo or other proprietary graphic of Everpay to link to the Site without the express written permission of Everpay. Further, you may not use, frame or utilize framing techniques to enclose any Everpay trademark, logo or other proprietary information, including the images found on the Site, the content of any text or the layout/design of any page or form contained on a page of the Site without Everpay's express written consent. Except as noted above, you are not conveyed any right or license by implication, estoppel or otherwise in or under any patent, trademark, copyright or proprietary right of Everpay or any third party.</p>
                                        <p>
                                            Everpay makes no claim or representation regarding, and accepts no responsibility for, the quality, content, nature or reliability of embedded content, third-party websites accessible via hyperlink or websites linking to the Site. Such sites are not under the control of Everpay and Everpay is not responsible for the content of any linked site or any link contained in a linked site, or any review, changes or updates to such sites. Everpay provides these links to you only as a convenience, and the inclusion of any link does not imply affiliation, endorsement or adoption by Everpay of any site or any information contained therein. When you visit other sites via links or embedded content, you should understand that our terms and policies no longer govern and that the terms and policies of those third party sites will now apply. You should review the applicable terms and policies, including privacy and data gathering practices, of any site to which you navigate from Everpay's Site.
                                        </p>
                                    </div>

                                    <h6 className="f_p f_size_22 f_500 t_color3">6. Third Party Content</h6>
                                    <div className="card-body">
                                        <p>Everpay may provide third party content on the Site and may provide links to web pages and content of third parties (collectively, "Third Party Content") as a service to those interested in this information. Everpay does not control, endorse or adopt any Third Party Content and makes no representation or warranties of any kind regarding the Third Party Content, including without limitation regarding its accuracy or completeness. You acknowledge and agree that Everpay is not responsible or liable in any manner for any Third Party Content and undertakes no responsibility to update or review any Third Party Content. Users use such Third Party Content contained therein at their own risk.</p>
                                    </div>

                                    <h6 className="f_p f_size_22 f_500 t_color3">7. Submissions</h6>
                                    <div className="card-body">
                                        <p>You acknowledge and agree that any questions, comments, suggestions, ideas, plans, notes, drawings, original or creative materials or other information that you provide to Everpay regarding Everpay, the Site (collectively, "Submissions") are non-confidential and shall become the sole property of Everpay. Everpay shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of Submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to you.</p>
                                    </div>

                                    <h6 className="f_p f_size_22 f_500 t_color3">8. Indemnification</h6>
                                    <div className="card-body">
                                        <p>You agree to defend, indemnify and hold harmless Everpay, its independent contractors, service providers and consultants, and their respective directors, employees and agents, from and against any claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable attorneys' fees) arising out of or related to (a) any actual or alleged breach of these Terms, the Terms of Service, the Acceptable Use Policy or any other Everpay agreement, policy or guideline (b) any actual or alleged violation of applicable laws or rules of any payment card association, network or company; (c) your wrongful or improper use of the Site; (d) any Submission or transaction submitted by you; or (e) your violation of the rights of any third party.</p>
                                    </div>

                                    <h6 className="f_p f_size_22 f_500 t_color3">9. Disclaimer</h6>
                                    <div className="card-body">
                                        <p>EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY Everpay, THE SITE AND SITE MATERIALS ARE PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. Everpay DISCLAIMS ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE SITE AND SITE MATERIALS. Everpay DOES NOT REPRESENT OR WARRANT THAT MATERIALS IN THE SITE ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. WHILE Everpay ATTEMPTS TO MAKE YOUR ACCESS AND USE OF THE SITE SAFE, Everpay CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SITE OR ITS SERVER(S) ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; THEREFORE, YOU SHOULD USE INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND DISINFECT VIRUSES FROM ANY DOWNLOAD.</p>
                                        <p>The laws of certain states or other jurisdictions do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply, some or all of the above disclaimers, exclusions or limitations may not apply to you.</p>
                                    </div>

         <h6 className="f_p f_size_22 f_500 t_color3">11. Modifications to the Site</h6>
                                    <div className="card-body">
                                        <p>Everpay reserves the right to modify or discontinue, temporarily or permanently, the Site or any features or portions thereof without prior notice. You agree that Everpay will not be liable for any modification, suspension or discontinuance of the Site or any part thereof.</p>
                                    </div>

                                    <h6 className="f_p f_size_22 f_500 t_color3">12. Applicable Law and Venue</h6>
                                    <div className="card-body">
                                        <p>These Terms and your use of the Site shall be governed by and construed in accordance with the laws of the State of New York, without resort to its conflict of law provisions. You agree that any action at law or in equity arising out of or relating to these Terms shall be filed only in the state and federal courts located in New York, York and you hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts over any suit, action or proceeding arising out of these Terms.</p>
                                    </div>

                                    <h6 className="f_p f_size_22 f_500 t_color3">13. Termination</h6>
                                    <div className="card-body">
                                        <p>Notwithstanding any of these Terms, Everpay reserves the right, without notice and in its sole discretion, to terminate your license to use the Site and/or to block or prevent your future access to, and use of, the Site.</p>
                                    </div>

                                    <h6 className="f_p f_size_22 f_500 t_color3">14. Severability</h6>
                                    <div className="card-body">
                                        <p>If any provision of these Terms shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default TermsContent;
