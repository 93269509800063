import React from 'react';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Breadcrumb from '../components/Breadcrumb';
import CardProcessingSection from '../components/CardProcessing/CardProcessingSecion';
import HostingActionTwo from '../components/HostingActionTwo';


const CardProcessing = () => {
    return (
        <div>
            <Breadcrumb
                breadcrumbClass="breadcrumb_area"
                imgName="breadcrumb/banner_bg.png"
                Ptitle="Card Processing"
                Pdescription={`Accept credit and debit card payments.<br> Process any U.S. or international card without a separate merchant account and gateway.`} />
            <CardProcessingSection/>
            <HostingActionTwo/>
            <FooterTwo FooterData={FooterData} />
        </div>
    )
}
export default CardProcessing;