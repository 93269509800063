import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
/*------ Pages-----*/
import PaymentProcessing from "./Pages/Payment-processing";
import About from "./Pages/About";
import Service from "./Pages/Service";
import Contact from "./Pages/Contact";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import Privacy from "./Pages/Privacy";
import Cookies from "./Pages/Cookies";
import ScrollToTopRoute from "./ScrollToTopRoute";
import ServiceDetails from "./Pages/ServiceDetails";
import CardProcessing from "./Pages/CardProcessing";
import Terms from "./Pages/Terms";
import IndustriesServed from "./Pages/IndustriesServed";
import Support from './Pages/Support'
import JobsHiring from "./Pages/JobsHiring";
import JobDetails from "./Pages/JobDetails";
import BecomeAPartner from "./Pages/BecomeAPartner";
import CustomNavbar from "./components/CustomNavbar";
import Security from "./Pages/Security";
import Pricing from './Pages/Pricing'
import CoinPayment from "./Pages/CoinPayments";
import DirectDebits from "./Pages/DirectDebits";
import SubscriptionCommerce from "./Pages/SubscriptionCommerce";


class App extends Component {
  componentDidMount() {
    // this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <div className="body_wrapper">
            <CustomNavbar cClass="custom_container" hbtnClass="new_btn" />
            <ScrollToTopRoute exact={true} path={"/"} component={PaymentProcessing} />
            <ScrollToTopRoute exact={true} path={"/privacy"} component={Privacy} />
            <ScrollToTopRoute exact={true} path={"/terms"} component={Terms} />
            <ScrollToTopRoute exact={true} path={"/cookie-preferences"} component={Cookies} />
            <ScrollToTopRoute exact={true} path={"/card-processing"} component={CardProcessing} />
            <ScrollToTopRoute exact={true} path={"/about-us"} component={About} />
            <ScrollToTopRoute path="/Contact" component={Contact} />
            <ScrollToTopRoute path="/SignIn" component={SignIn} />
            <ScrollToTopRoute path="/SignUp" component={SignUp} />
            <ScrollToTopRoute exact={true} path="/industries-served" component={IndustriesServed} />
            <ScrollToTopRoute exact={true} path="/help" component={Support} />
            <ScrollToTopRoute exact={true} path="/careers" component={JobsHiring} />
            <ScrollToTopRoute exact={true} path="/job/job-id" component={JobDetails} />
            <ScrollToTopRoute exact={true} path="/become-a-partner" component={BecomeAPartner} />
            <ScrollToTopRoute exact={true} path="/services" component={Service} />
            <ScrollToTopRoute exact={true} path="/services/service-details" component={ServiceDetails} />
            <ScrollToTopRoute exact={true} path="/security" component={Security} />
            <ScrollToTopRoute exact={true} path="/pricing" component={Pricing} />
            <ScrollToTopRoute exact={true} path="/coin-payments" component={CoinPayment} />
            <ScrollToTopRoute exact={true} path="/direct-debits" component={DirectDebits} />
            <ScrollToTopRoute exact={true} path="/subscription-commerce" component={SubscriptionCommerce} />

            {/* <ScrollToTopRoute
            path="/Digital-marketing"
            component={DigitalMarketing}
          />
          <ScrollToTopRoute
            path="/Payment-processing"
            component={PaymentProcessing}
          />
          <ScrollToTopRoute path="/HR-Management" component={HRManagement} />
          <ScrollToTopRoute path="/Startup" component={Startup} />
          <ScrollToTopRoute path="/Home-CRM" component={HomeCRM} />
          <ScrollToTopRoute path="/About" component={About} />
          <ScrollToTopRoute path="/Service" component={Service} />
          <ScrollToTopRoute path="/Process" component={Process} />
          <ScrollToTopRoute path="/Team" component={Team} />
          <ScrollToTopRoute path="/Portfolio-2col" component={Portfolio2col} />
          <ScrollToTopRoute path="/Portfolio-3col" component={Portfolio3col} />
          <ScrollToTopRoute
            path="/Portfolio-fullwidth-4col"
            component={Portfoliofull4col}
          />
          <ScrollToTopRoute
            path="/PortfolioSingle"
            component={PortfolioSingle}
          />
          <ScrollToTopRoute path="/Bloglist" component={Bloglist} />
          <ScrollToTopRoute path="/BlogSingle" component={BlogSingle} />
          <ScrollToTopRoute path="/BlogGridPage" component={BlogGridPage} />
          <ScrollToTopRoute path="/Landing" component={Landing} />
          <ScrollToTopRoute path="/home-support" component={homesupport} />
          <ScrollToTopRoute path="/HomeHosting" component={homeHosting} />
          <ScrollToTopRoute path="/Home-ERP" component={homeERP} />
          <ScrollToTopRoute path="/HomeSecurity" component={homeSecurity} />
          <ScrollToTopRoute
            path="/HomeAppShowcase"
            component={HomeAppShowcase}
          />
          <ScrollToTopRoute
            path="/Home-software-dark"
            component={homeSoftwareDark}
          />
          <ScrollToTopRoute path="/Home-cloud" component={HomeCloud} />
          <ScrollToTopRoute path="/Home-Tracking" component={HomeTracking} />
          <ScrollToTopRoute path="/Home-event" component={HomeEvent} />
          <ScrollToTopRoute path="/Home-chat" component={HomeChat} />
          <ScrollToTopRoute path="/Price" component={Price} />
          <ScrollToTopRoute path="/Faq" component={Faq} />
          <ScrollToTopRoute path="/ServiceDetails" component={ServiceDetails} />
          <ScrollToTopRoute path="/Privacy" component={Privacy} /> */}
          </div>
        </Switch>
      </Router>
    );
  }
}

export default App;
