import React from 'react';
import PaymentBanner from '../components/Banner/PaymentBanner';
import AppFeaturesTwo from '../components/Features/AppFeaturesTwo';
import Funfact from '../components/Funfact';
import SecurityFeatures from "../components/Features/SecurityFeatures";
import SecurityService from "../components/Service/SecurityService";
//import PaymentFeatures from '../components/Features/PaymentFeatures';
//import PaymentService from '../components/Service/PaymentService';
//import ServiceData from '../components/Service/ServiceData';
import HostingMap from '../components/HostingMap';
//import AgencyAbout from '../components/About/AgencyAbout';
//import Paymentprocess from '../components/Paymentprocess';
import CloudFeatures from '../components/Features/CloudFeatures';
import HostingActionTwo from '../components/HostingActionTwo';
import FooterData from '../components/Footer/FooterData';
import FooterTwo from '../components/Footer/FooterTwo';
import CookieNotice from '@mirzalikic/react-cookie-notice';

const Paymentprocessing = () => {
    return(
        <div>
            <PaymentBanner/>
            <AppFeaturesTwo/>
            <SecurityFeatures/>
        <Funfact fclass="fun_fact_area_two" manImg="home8/fact.png" tClass="t_color3" ftitle="Trusted" fdescription="Everpay truly offers merchants a complete secure omni-channel payment processing platform with the global flexibility to support your in-store, online and mobile needs, and the scalability to power sales today and well into the future."
        CnumberOne="100" CnumberTwo="10"/>
            <SecurityService/>
            <HostingMap/>
            <CloudFeatures/>
            <HostingActionTwo/>
            <FooterTwo FooterData={FooterData}/>
 <CookieNotice>
    <h3 tClass="white_color">This website uses cookies</h3>
    <p>We use cookies to provide website functionality, to analyze traffic on our Everpay Sites, personalize content, serve targeted advertisements and to enable social media functionality. Our Cookie Statement provides more information and explains how to update your cookie settings. </p>
 </CookieNotice>
        </div>
    )
}
export default Paymentprocessing;