import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal';

class SeoTitle extends Component {

    createMarkup(content) { return { __html: content }; };

    render() {
        var { Title, TitleP } = this.props;
        return (
            <div className="seo_sec_title text-center mb_70">
                <Reveal effect="fadeInUp" duration={1300}><h2>{Title}</h2></Reveal>
                <Reveal effect="fadeInUp" duration={1600}><span className="f_500 f_size_20 " dangerouslySetInnerHTML={this.createMarkup(TitleP)}></span></Reveal>
            </div>
        )
    }
}
export default SeoTitle;
