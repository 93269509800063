import React, { Component } from 'react';

class CardProcessingPricing extends Component  {
    render() {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Note</th>
                        <th>Price</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Foreign currency conversion</td>
                        <td>2% fee on top of card processing rate</td>
                        <td>2%</td>
                    </tr>
                    <tr>
                        <td>Authorization hold</td>
                        <td></td>
                        <td>$2</td>
                    </tr>
                    <tr>
                        <td>Refund</td>
                        <td>processing fee of 2.9% is returned, but the 30&cent; is non-refundable</td>
                        <td>$0</td>
                    </tr>
                    <tr>
                        <td>Failure</td>
                        <td></td>
                        <td>$0</td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

export default CardProcessingPricing;
