import React, { Component } from 'react';
import SeoTitle from '../../Title/SeoTitle';
import CoinPaymentSteps from './CoinPaymentSteps';

class CoinPaymentSection extends Component {
    render() {
        return (
            <div className="mt_40 bg_color">
          <SeoTitle sClass="sec_title text-center mb_70" Title='Enable your business to accept crypto payments' TitleP='Add cryptocurrency as a payment method in 3 steps'/>

                <section className="process_area bg_color">
                    <div className="container">
                        <div className="features_info">
                            <CoinPaymentSteps
                                rowClass="row flex-row-reverse"
                                aClass="pr_70 pl_70"
                                stepImage="process_1.png"
                                iImg="icon01.png"
                                ftitle="Download and install"
                                descriptions="Accessible, code-free, one-minute plugin. API custom integration." />
                            <CoinPaymentSteps
                                rowClass="row"
                                aClass="pl_100"
                                stepImage="process_2.png"
                                iImg="icon02.png"
                                ftitle="Add your wallet, or create one"
                                descriptions="Indicate your preferred payment mode.
                                    Crypto, or local currencies.
                                    Convenient fiat conversion for you." />
                            <CoinPaymentSteps
                                rowClass="row flex-row-reverse"
                                aClass="pr_70 pl_70"
                                stepImage="process_3.png"
                                iImg="icon3.png"
                                ftitle="Start tracking your sales"
                                descriptions="See your first crypto sale in no time.
                                Track your sales on our dashboard." />
                <div className="row">
<a href="https://id.everpayinc.com/signup" className="btn_hover agency_banner_btn text-right">Get Started for Free</a>
</div>
                        </div>  

                    </div>
                </section>
            </div>
        )
    }
}
export default CoinPaymentSection;
