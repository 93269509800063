import React, { Component } from 'react';
// import Fade from 'react-reveal/Fade';
import SeoTitle from './../Title/SeoTitle'
import HowDoesItWork from './HowDoesItWork';
import CardProcessingPricing from './../Pricing/CardProcessingPricing';

class CardProcessingSection extends Component {
	render() {
		return (
			<section className="seo_service_area sec_pad">
				<div className="container">
					<HowDoesItWork />
					<div className="row seo_service_item text-center">
						<div className="col-md-6 col-lg-6">
<img alt="" src="https://templates.envytheme.com/luvion/default/assets/img/4.jpg" className="full-image" />
						</div>
						<div className="col-md-6 col-lg-6">
			<SeoTitle Title='Collecting card info' TitleP={`Charge a card by collecting the card number and expiration date. Information is securely passed through <a href='https://docs.geteverpay.com/1.1/guides/everpay-js/'>everpay.js</a>, bypassing your servers and removing your need to become <a href='http://support.everpayinc.com/hc/en-us/articles/200173030-Do-I-need-to-be-PCI-compliant-'>PCI compliant</a>.`} />
		<a href=".#">View card processing best practice</a>
						</div>
					</div>

					<div className="row seo_service_item text-center">
						<div className="col-md-6 col-lg-6">
		<SeoTitle Title='Recurring billing' TitleP={`Everpay’s open-source, recurring payments system, <a href='https://github.com/everpay/everpay'>Everpay</a>, allows you to schedule charges at specific times.`} />
				<a href=".#"> Learn more about Everpay</a>
						</div>
						<div className="col-md-6 col-lg-6">
<img alt="" src="https://templates.envytheme.com/luvion/default/assets/img/5.png" className="full-image" />
						</div>
					</div>

					<div className="row seo_service_item text-center">
						<div className="col-md-6 col-lg-6">
							<img alt="" src="https://templates.envytheme.com/luvion/default/assets/img/6.jpg" className="full-image" />
						</div>

						<div className="col-md-6 col-lg-6">
							<SeoTitle Title='Card authorizations' TitleP={`Reserve funds on a credit card for up to seven days by issuing a card authorization.`} />
							<p>
								<a href=".#"> Learn more about authorization use cases such as crowdfunding</a>
							</p>
						</div>
					</div>

					<div className="row seo_service_item text-center">
						<div className="col-md-6 col-lg-6">
							<SeoTitle Title='Card statement descriptor' TitleP={`Modify the card statement soft descriptor on a per-transaction basis. Each descriptor begins with EVR* followed by your 18-character-long description.`} />
							<a href=".#"> Learn more about setting the soft descriptor</a>
						</div>
						<div className="col-md-6 col-lg-6">
							<img alt="" src="https://templates.envytheme.com/luvion/default/assets/img/features-img1.png" className="full-image" />
						</div>

					</div>

					<div className="row seo_service_item text-center">
						<div className="col-md-6 col-lg-6">
		<img alt="" src="https://templates.envytheme.com/luvion/default/assets/img/2.png" className="full-image" />
						</div>

						<div className="col-md-6 col-lg-6">
							<SeoTitle Title='Chargebacks & Disputes' TitleP={`In the event of a chargeback, Everpay will notify you to help gather the documents necessary to fight the chargeback.`} />
			<a href=".#"> Learn more about the chargeback process</a>
						</div>
					</div>

					<div className="seo_service_item text-center">

						<SeoTitle Title='Pricing' TitleP={`<strong>Percent:</strong> <span>2.9</span> <strong>Cent:</strong> <span>30</span>`} />
						<CardProcessingPricing/>
					</div>
				</div>
			</section>
		)
	}
}
export default CardProcessingSection;