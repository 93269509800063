import React, { Component } from 'react';
// import SeoTitle from '../Title/SeoTitle';
import { Link } from 'react-router-dom';

class JobsHiringContent extends Component {
    render() {
        return (
            <section>
                <div className="container">

                    <h1 className="mt_100 mb_50">Latest Jobs</h1>
                    <div className="jobs-item mt_50 mb_50">
                        <div className="card mb_20">
                            <div className="card-body">
                                <h3>Enterprise Risk Management / Gestion du risque</h3>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Job Title</th>
                                            <th>Location</th>
                                            <th>Job Type</th>
                                            <th>Date Posted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><Link to="/job/job-id">Credit Analyst</Link></td>
                                            <td>Montreal, PQ</td>
                                            <td>Full-Time Regular/régulier à plein temps</td>
                                            <td>Thursday, Nov 5</td>
                                        </tr>
                                        <tr>

          <td><Link to="/job/job-id">Enterprise Risk Management Specialist</Link></td>
                                            <td>Montreal, PQ</td>
                                      <td>Full-Time Regular/régulier à plein temps</td>
                                            <td>Monday, Oct 23</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card mb_20">
                            <div className="card-body">
                                <h3>Finance / Finance</h3>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Job Title</th>
                                            <th>Location</th>
                                            <th>Job Type</th>
                                            <th>Date Posted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                          <td><Link to="job/job-id">Business Data Analyst</Link></td>
                                            <td>Etobicoke, ON</td>
                                    <td>Full-Time Regular/régulier à plein temps</td>
                                           <td>Monday, Nov 9</td>
                                        </tr>
                                        <tr>
                                            <td><Link to="/job/job-id">Senior Financial Analyst - Strategy & Performance</Link></td>
                                            <td>Montreal, ON</td>
                                    <td>Full-Time Regular/régulier à plein temps</td>
                                            <td>Wednesday, Oct 26</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card mb_20">
                            <div className="card-body">
                                <h3>Legal / Légal</h3>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Job Title</th>
                                            <th>Location</th>
                                            <th>Job Type</th>
                                            <th>Date Posted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                   <td><Link to="/job/job-id">Regulatory Compliance Manager</Link></td>
                                            <td>Montreal, PQ</td>
                                       <td>Full-Time Regular/régulier à plein temps</td>
                                            <td>Saturday, Oct 31</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card mb_20">
                            <div className="card-body">
                                <h3>Operations / Opérations</h3>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Job Title</th>
                                            <th>Location</th>
                                            <th>Job Type</th>
                                            <th>Date Posted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
<td><Link to="/job/job-id">Gestionnaire principal des installations / Senior Manager, Facilities</Link></td>
                                            <td>Etobicoke, ON</td>
                                        <td>Full-Time Regular/régulier à plein temps</td>
                                            <td>Saturday, Oct 31</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* <!-- tab-content --> */}
                </div>
            </section>)
    }
}

export default JobsHiringContent;
