import React, { Component } from 'react';
import DirectDebitsSectionContent from './DirectDebitsSectionContent';

class DirectDebitsSection extends Component {
    render() {
        return (
            <section className="faq_area bg_color mt_40">
                <div className="container">
                    <div className="row text-left">
                        <DirectDebitsSectionContent />
                    </div>
                </div>
            </section>
        )
    }
}
export default DirectDebitsSection;
