import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SupportContent extends Component {
    render() {
        return (
            <section className="contact_info_area sec_pad bg_color">
                <div className="container">
                    <div className="tect-center">
                        <h3>Welcome to Everpay Support</h3>
                        <h1>How Can We Help You?</h1>    
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <span>
                                Due to the high volume of calls on most major telecom networks, some providers are experiencing technical issues affecting toll-free numbers.
                                Some merchants may not be able to reach our contact centre. 
                                We encourage merchants to continue trying, use our <Link to={'/contact-us'}>Call Me Later feature</Link>.
                            </span>
                        </div>
                    </div>

                    <div className="mt_60 card">
                        <div className="card-body">
                            <h3 className="text-center">Top Topics</h3>
                            <hr/>
                            <div className="topic">
                                <h4>Device Help</h4>
                                <a href=".#">Looking for help with your Moneris device? We have you covered with our product support pages.</a>
                                <hr/>
                            </div>
                            <div className="topic">
                                <h4>Merchant Direct</h4>
                                <a href=".#">Access Monthly Statements, Reports and Manage your Moneris Account all via Moneris' online merchant portal.</a>
                                <hr/>
                            </div>

                            <div className="topic">
                                <h4>Topic 3</h4>
                                <a href=".#">Looking for help with your Moneris device? We have you covered with our product support pages.</a>
                                <hr/>
                            </div>
                            <div className="topic">
                                <h4>Topic 4</h4>
                                <a href=".#">Access Monthly Statements, Reports and Manage your Moneris Account all via Moneris' online merchant portal.</a>
                                <hr/>
                            </div>

                            <div className="topic">
                                <h4>Topic 5</h4>
                                <a href=".#">Looking for help with your Moneris device? We have you covered with our product support pages.</a>
                                <hr/>
                            </div>
                            <div className="topic">
                                <h4>Topic 6</h4>
                                <a href=".#">Access Monthly Statements, Reports and Manage your Moneris Account all via Moneris' online merchant portal.</a>
                                <hr/>
                            </div>
                        </div>
                    </div>

                    <div className="card mt_60">
                        <div className="card-body">
                            <h4>
                                Need Additional Help?
                            </h4>
                            <p>
                                Talk to one of our business advisers for additional troubleshooting.
                            </p>
                            <button className="btn btn-info">
                               Contact Us
                            </button>

                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default SupportContent;