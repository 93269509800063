import React, { Component } from 'react';
import ContactsMap from '../components/ContactsMap';
import contactAPI from './../actions/api/contact';

class Contacts extends Component {
    constructor() {
        super()
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            loading: false,
            info: ''
        }
        this.handleChange = this.handleChange.bind(this)

    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    submitForm = (e) => {
        e.preventDefault();
        const { loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({
            loading: true
        })
        const data = { ... this.state }
        console.log('data-', data)
        const keys = [ 'email', 'message', 'name', 'subject' ]
        const isInvalid = keys.find((key) => !data[key])
        if (isInvalid) {
            this.setState({
                info: 'All Fields required!',
                loading: false
            })
        } else {
            contactAPI.sendContactFrom(data).then((res) => {
                this.setState({
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                    info: 'Your message succesfully sent!',
                    loading: false
                })
            }, (error) => {
                this.setState({
                    info: 'Opps! There is something wrong. Please try again',
                    loading: false
                })
            })
        }
    }

    render() {
        // const {name, email, subject, message, emailStatus} = this.state;
        return (
            <section className="contact_info_area sec_pad">
                <div className="container">
                    <div className="hosting_title security_title text-center mt_20 mb_50">
                        <h2 className="wow fadeInUp mt_20 mb_70" data-wow-delay="0.2s"><span className="f_size_50"> </span> Our payment specialist are always ready to answer your inquires</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Canada</h6>
                                <p className="f_400 f_size_15">5700 Yonge Street, Suite #200 <br /> Toronto, ON, M2M4K2</p>
                                <p className="f_400">Direct:<a href="tel:+14168476784" className="f_400">+1.416.847.6784</a></p>
                                <p className="f_400">Toll Free:<a href="tel:+18005666003">+1.800.566.6003</a></p>
                            </div>
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">United States</h6>
                                <p className="f_400 f_size_15">5200 Riverside Drive, Suite #15G <br />New York, NY, 10025</p><p className="f_400">Direct:<a href="tel:+16468476784" className="f_400">+1.646.847.6784</a></p>
                                <p className="f_400">Toll Free:<a href="tel:+18005666003">+1.800.566.6003</a></p>
                            </div>
                        </div>

                        <div className="col-lg-3">
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Mexico</h6>
                                <p className="f_400 f_size_15">455F Calle 49 y Calle 52, Centro <br />Oriente, Merida, Yucatan, 97000</p>
                                <p className="f_400">Direct:<a href="tel:+529994703727" className="f_400">+52.999.470.3727</a></p>
                                <p className="f_400">Toll Free:<a href="tel:+18005666003">+1.833.470.3727</a></p>
                            </div>
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">United Kingdom</h6>
                                <p className="f_400 f_size_15">520 Riverside Dr., #15G <br /> Manchester, NY, 10025</p>
                                <p className="f_400">Direct:<a href="tel:+14168476784" className="f_400">+1.416.847.6784</a></p>
                                <p className="f_400">Toll Free:<a href="tel:+044 3335666003">+044.333.566.6003</a></p>
                            </div>
                        </div>

                        <div className="contact_form col-lg-6">

                            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Leave a Message</h2>
                            <div className="card mb_40">
                                <div className="card-body bg_color">
                                    <form onSubmit={this.submitForm} className="contact_form_box" method="post" id="contactForm" data-netlify="true" data-netlify-honeypot="bot-field">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group text_box">
                                                    <input type="hidden" name="form-name" value="contact" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group text_box">
                                                    <input type="text" id="name" name="name" placeholder="Your Name" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group text_box">
                                                    <input type="text" name="email" id="email" placeholder="Your Email" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group text_box">
                                                    <input type="text" id="subject" name="subject" placeholder="Subject" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group text_box">
                                                    <textarea onChange={this.handleChange} name="message" id="message" cols="20" rows="5" placeholder="Enter Your Message . . ."></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn_three mb_20">Send Message</button>
                                    </form>
                                    <div>{this.state.info}</div>
                                    {/* <div id="error">Opps! There is something wrong. Please try again</div> */}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <ContactsMap />
            </section>
        )
    }
}

export default Contacts;