import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import WhyUsWidget from './FooterWidget/WhyUsWidget.js'
import WhatWeDoWidget from './FooterWidget/WhatWeDoWidget'
import TheCompanyWidget from './FooterWidget/TheCompanyWidget'
import ResourcesWidget from './FooterWidget/ResourcesWidget'
import Reveal from 'react-reveal/Reveal'

const url = "//2f6853989c35cf1de5c88912fea7d517-us6.list-manage.com/subscribe/post?u=zefzefzef&id=fnfgn";

//  eslint-disable-next-line
const SimpleForm = () => <MailchimpSubscribe url={url}/>
 
class FooterTwo extends Component {

  submit() {
   
    }
    render() {
        var { fClass } = this.props;
        let FooterData = this.props.FooterData;
        let status = 'sending';
        let message = '';
         // eslint-disable-next-line
        let email = 'test@gmail.com'; 
        return (
            <footer className={`footer_area payment_footer_area f_bg ${fClass}`}>
                <div className="footer_top">
                    <div className="container">
                        <div className="row">
                            {
                                FooterData.CompanyWidget.map(widget => {
                                    return (
                                        <Reveal effect="fadeInUp" key={widget.id}>
                                            <div className="col-lg-3 col-md-6">
         <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s">
                         <a href="." className="f-logo">
         <img className="logo" src="https://res.cloudinary.com/lmj6rf6tz/image/upload/c_scale,w_105/v1604178445/White_logo_HD_xcpbgf.png" height="25" alt="Everpay" />
                                                    </a>
                                                    <div className="widget-wrap">
<p className="none f_600 f_size_16 mb-0 l_height28"><span></span> </p>
  <p className="f_600 f_size_16 mb-0 l_height28"><span>Signup for our free newsletter</span> </p>
            </div>
  <div
      style={{
        borderRadius: 2,
        display: "inline-block"
      }}
    >
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    
      <input
        style={{ fontSize: "2em", padding: 5 }}
        ref={node => (email = node)}
        type="email"
        className="form-control memail"
        placeholder="Your email"
      />
      <button className="btn btn-submit" style={{ fontSize: "2em", padding: 5 }} onClick={this.submit}>
       <i className="ti-arrow-right"></i>
      </button>
    </div>

                                                </div>
                                            </div>
                                        </Reveal>
                                    )
                                })
                            }
                        <WhyUsWidget ftitle="Why Us" FooterData={FooterData} />
                        <WhatWeDoWidget ftitle="What We Do" FooterData={FooterData} />
                        <TheCompanyWidget ftitle="The Company" FooterData={FooterData} />
                        <ResourcesWidget ftitle="Resources" FooterData={FooterData} />
                        </div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-5 col-sm-6">
                                <p className="w_color mb-0 f_300 f_size_13">
                                    {FooterData.copywrite}
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-3 col-sm-6">
                                <div className="f_social_icon_two text-center">
                                    {
                                        FooterData.socialIcon.map(item => {
                                            return (
                  <a href={item.url} key={item.id}><i className={item.icon}></i></a>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                       <ul className="list-unstyled f_menu text-right f_300 f_size_14">
  <li className="f_300 f_size_14 mb-0"><Link to="/privacy">Privacy</Link></li>
<li className="f_300 f_size_13 mb-0"><Link to="/terms">Terms of Use</Link></li>
<li className="f_300 f_size_13 mb-0"><Link to="/cookie-preferences">Cookie Preferences</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        )
    }
}
export default FooterTwo;