import React, { Component } from 'react';

class HaveQuestion extends Component {
    constructor() {
        super()
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: ''
        }
        this.handleChange = this.handleChange.bind(this)

    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        // const {name, email, subject, message, emailStatus} = this.state;
        return (
            <section className="contact_info_area sec_pad bg_color">
                <div className="container">
                    <div className="row">
                        <div className="contact_form">
                            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Leave a Message</h2>
                            <form onSubmit={this.submitForm} className="contact_form_box" method="post" id="contactForm">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <textarea id="question" name="question" placeholder="Your Question" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <input type="text" name="b_name" id="b_name" placeholder="Business Name" onChange={this.handleChange} />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <input type="text" name="name" id="text" placeholder="Your Name" onChange={this.handleChange} />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <input type="email" name="Email" id="text" placeholder="Email address" onChange={this.handleChange} />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <input type="text" name="name" id="text" placeholder="Your Name" onChange={this.handleChange} />
                                        </div>
                                    </div>


                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <input type="text" name="phone_number" id="phone_number" placeholder="Phone number" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn_three">Send Message</button>
                            </form>
                            <div id="success">Your message succesfully sent!</div>
                            <div id="error">Opps! There is something wrong. Please try again</div>
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default HaveQuestion;