import React, {Component} from 'react';
import SecurityServiceItem from './SecuirtyServiceItem';

class SecurityService extends Component {
    render(){
        return(
            <section className="h_security_about_area">
                <div className="container">
               
                    <SecurityServiceItem image="paying-for-order.jpg" Title="Everpay gives retailers all the necessary tools to succeed."
                    description="Seamlessly integrate our POS devices designed exclusively for small to medium businesses and for easy hassle-free EVM payment processing."/>

                </div>

            </section>
        )
    }
}

export default SecurityService;