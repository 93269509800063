import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
// import PricingContent from '../components/Pricing/PricingContent';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Price from '../components/Price';

const Pricing = () => {
    return(
        <div>
            <Breadcrumb
                breadcrumbClass="breadcrumb_area"
                imgName="breadcrumb/banner_bg.png"
                Ptitle="Transparent Pricing"
                Pdescription="Everpay offers a straightforward, all-inclusive Pricing structure. <br>No setup costs. No monthly contracts. No hidden fees."/>
            <Price/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Pricing;