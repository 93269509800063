import React, { Component } from 'react';
import Slider from 'react-slick'
import { Link } from 'react-router-dom';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '100px',
    responsive: [
        {
            breakpoint: 1250,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '100px',
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px',
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px',
            }
        }
    ]
};

class SubscriptionCommerceContent extends Component {
    render() {

        return (
            <div className="mt_100">

                <Slider className="service_carousel" {...settings}>
                    <div className="service_item">
                        <Link to="/services/service-details">
                <div className="icon s_icon_one"><i className="ti-check"></i></div>
  <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Subscription Plan Manager</h4>
                            <p>Create an unlimited number of plans to improve customer retention. Provide various pricing tiers and flexible billing periods. No matter what you sell — do it in the most effective way!</p>
      <img className="float-right" src={require('../../img/home2/undraw.png')} alt="" />
                        </Link>
                    </div>
                    <div className="service_item">
                        <Link to="/services/service-details">
            <div className="icon s_icon_two"><i className="ti-location-arrow"></i></div>
           <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Retry Logic</h4>
                            <p>The reasons for the failure of a transaction are usually associated with insufficient funds, card limit or expiration date. Let us handle it and watch your revenue grow.</p>
       <img className="float-right" src={require('../../img/home2/inbox.png')} alt="" />
                        </Link>
                    </div>
                    <div className="service_item">
                        <Link to="/services/service-details">
                            <div className="icon s_icon_three"><i className="ti-search"></i></div>
                            <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Trials and discounts</h4>
                            <p>Let users try your products so they find them worth paying for later. Give them time to make a well-thought-out decision and convert users into paying customers.</p>
                            <img className="float-right" src={require('../../img/home2/report.png')} alt="" />
                        </Link>
                    </div>
                    <div className="service_item">
                        <Link to="/services/service-details">
                            <div className="icon s_icon_four"><i className="ti-stats-up"></i></div>
                            <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Customer Manager</h4>
                            <p>Get full control over your payments and your customer’s behaviour. Browse the payment history, manage transactions, create charges, or assign customers to certain subscriptions.</p>
                            <img className="float-right" src={require('../../img/home2/undraw.png')} alt="" />
                        </Link>
                    </div>

                </Slider>
            </div>
        )
    }
}
export default SubscriptionCommerceContent;