import React from 'react';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Breadcrumb from '../components/Breadcrumb';
import IndustriesServedContent from '../components/IndustriesServed/IndustriesServedContent';

const IndustriesServed = () => {
    return (
        <div>
            <Breadcrumb
                breadcrumbClass="breadcrumb_area"
                imgName="breadcrumb/banner_bg.png"
                Ptitle="Industries Served"
                Pdescription="" />
            <IndustriesServedContent />
            <FooterTwo FooterData={FooterData} />
        </div>
    )
}
export default IndustriesServed;