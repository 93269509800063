import React from 'react';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Breadcrumb from '../components/Breadcrumb';
import SecurityContent from '../components/Security/SecurityContent';

const Security = () => {
    return (
        <div>
            <Breadcrumb
                breadcrumbClass="breadcrumb_area"
                imgName="breadcrumb/banner_bg.png"
                Ptitle="Security"
                Pdescription="Everay's development team is strongly committed to responsible reporting and disclosure of security-related issues.<br />As such, we've developed a policy for handling security issues." />
            <SecurityContent />
            <FooterTwo FooterData={FooterData} />
        </div>
    )
}
export default Security;