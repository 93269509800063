import React, {Component} from 'react';

class SecuirtyServiceItem extends Component {
    render(){
        let{image, rClass, Title, description}= this.props;
        return(            
<div className={`row h_analytices_features_item align-items-center mt_50 ${rClass}`}> 

<div className="col-lg-12 hosting_title security_title text-center mb_90">
 <h2 className="wow fadeInUp mb_90" data-wow-delay="0.2s"><span className="f_size_50">POS solutions for all</span>A hardware partner to grow with</h2>
                </div>


<div className="row mt_20">
                <div className="col-lg-6">
                    <div className="h_security_img text-center">
 <img className="wow fadeInUp" src={require("../../img/home-security/" + image)} alt=""/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="h_analytices_content">
                 <h2 className="wow fadeInUp mb_40" data-wow-delay="0.1s">{Title}</h2>
                      <p className="wow fadeInUp" data-wow-delay="0.3s">{description}</p>
                        <ul className="list-unstyled">
                            
                            <li className="wow fadeInUp" data-wow-delay="0.7s"><span>All-in-one Terminals: </span> From streamline and simple to all the bells and whistles, there’s a Everpay terminal to fit your unique business.</li>

                            <li className="wow fadeInUp" data-wow-delay="0.9s"><span>Mobile Point of Sale Solutions:</span> Manage your customers, employees and business from your Iphone, Windows or Android phone and tablets.</li>

<li className="wow fadeInUp" data-wow-delay="0.5s"><span>Accept magstripe, chip and NFC payments:</span> Our mPOS solutions allow you to accept and manage all types of payments anytime, anywhere.</li>
                        </ul>
<div className="wow fadeInUp text-center mt_60">
<a href="https://shop.everpayinc.com" className="price_btn btn_hover text-center mb_30">Browse our Store</a></div>

                    </div>
</div>
                </div>
            </div>
            
        )
    }
}

export default SecuirtyServiceItem;