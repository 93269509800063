import React, { Component } from 'react';
class PolicyContent extends Component {
    render() {
        return (
            <section className="faq_area bg_color sec_pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab-content faq_content" id="myTabContent">
                                <div className="tab-pane fade show active" id="purchas" role="tabpanel" aria-labelledby="purchas-tab">
   <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Last Updated: 29/10/2020</h3>
                                    <div className="card-body">
                                        This Privacy Policy ("Policy") explains how your personal information is collected, used and disclosed by Everpay and its subsidiaries and affiliated companies ("Everpay"). This Policy applies to the websites of Everpay, including <a href="https://everpayinc.com"> https://everpayinc.com</a> ("Sites") and Everpay's other products, services or any other features, technologies or functionalities offered by us on our Sites or through any other means (collectively, "Services"). This Policy does not apply to information you provide directly to third party websites or third party applications that use Everpay's Services. We do not control the activities of such third parties and advise you to consult their privacy policies before you share your personal information.
                                    </div>

                                    <h3 className="f_p f_size_22 f_500 t_color3 mb_20">PERSONAL INFORMATION COLLECTED</h3>
                                    <div className="card-body">
                                        <p>We collect information about you in various ways when you use our Sites and Services. For example, we collect information you provide to us when you register for a Everpay account or sign up through a partner marketplace, initiate or accept a payment, request customer service or respond to a Everpay survey. Such personal information may include your name, address, email address, credit card information and bank account and routing numbers. Before you use the Services, we may also require you to provide additional information that we can use to verify your identity, such as your date of birth, Social Security number or other information. Finally, we may obtain information about you from other sources, including third party websites or applications that offer the Everpay Services, and combine that with information we collect on our Sites and through our Services.</p>
                                        <p>When you visit our Sites or use our Services, some information is automatically collected. For example, when you visit our Sites, your computer's operating system, Internet Protocol ("IP") address, access times, browser type and language and the website you visited before our Sites are logged automatically. We also collect information about your usage and activity on our Sites and Services.
                                        </p>
                                        <p>
                                            <strong>Cookies.</strong> We automatically collect information using "cookies." Cookies are small data files stored on your hard drive by a website. Among other things, cookies help us improve our Sites, Services and your experience. We use browser cookies to see which areas and features are popular and to count visits to our Sites. We also use flash cookies for fraud prevention purposes. You can usually choose to set your browser to remove and/or reject cookies. For instructions on removing or rejecting flash cookies, please see Adobe Flash Player and Microsoft Silverlight. If you choose to remove or reject cookies, this could affect certain features or services of our Sites or Services. Please note that you may also encounter cookies from third parties when using the Everpay Services on websites that we do not control.
                                        </p>
                                        <p>
                                            <strong>Web Beacons.</strong>
                                            We may also collect information using Web beacons. Web beacons are electronic images that may be used on our Sites, in our Services or in our emails. We use Web beacons to deliver cookies, count visits, understand usage and campaign effectiveness and to tell if an email has been opened and acted upon.
                                        </p>
                                    </div>
                                    <h3 className="f_p f_size_22 f_500 t_color3 mb_20">USE OF PERSONAL INFORMATION WE COLLECT</h3>
                                    <div className="card-body">
                                        <p>We use personal information collected through our Sites and Services for purposes described in this Policy or otherwise disclosed to you. For example, we may use personal information to:</p>
                                        <ul>
                                            <li>
                                                operate and improve our Sites and Services;
                                            </li>
                                            <li>
                                                respond to your comments and questions and provide customer service;
                                            </li>
                                            <li>
                                                process transactions and send you related information, including confirmations, receipts, invoices, technical notices, updates, security alerts and support and administrative messages;
                                            </li>
                                            <li>
                                                verify your identity, prevent fraud and comply with money transmission laws;
                                            </li>
                                            <li>
                                                resolve disputes, collect fees and troubleshoot problems;
                                            </li>
                                            <li>
                                                communicate with you about new contests, promotions, rewards, upcoming events and other news about Everpay and our selected partners; and
                                            </li>
                                            <li>
                                                link or combine with other personal information we get from third parties to help understand your needs and provide you with better service.
                                            </li>
                                        </ul>
                                        <p>
                                            Everpay stores and processes personal information in the United States.
                                        </p>
                                    </div>


                                    <h3 className="f_p f_size_22 f_500 t_color3 mb_20">SHARING OF PERSONAL INFORMATION</h3>
                                    <div className="card-body">
                                        <p>We may share your personal information with the person or company that you are paying, or that is transferring money to you, for purposes of processing the transaction. We may also share your personal information with payment processors, banks and other entities in the financial system to process your transactions and maintain your account. We may also share your unique user ID with third parties that integrate the Everpay services into their sites and applications; however, we will not share any personal information with these third parties.</p>
                                        <p>We may also share your personal information with third party vendors, consultants and other service providers who work on our behalf (e.g., fraud and identity theft prevention and security auditing services). Furthermore, to verify your identity, we may share your personal information with third-party identity-verification services such as Inflection and/or Lexus Nexus.
                                        </p><p>Finally, we may also share your information as follows:</p>
                                        <ul>
                                            <li>
                                                with credit bureaus to report account information, as permitted by law;
                                            </li>
                                            <li>
                                                to comply with laws or to respond to lawful requests and legal process; to protect the rights and property of Everpay, our agents, users and others, including to enforce our terms, policies and guidelines; or in an emergency to protect the personal safety of Everpay, our users or any other person;
                                            </li>
                                            <li>
                                                in connection with any merger, sale of company assets, financing or acquisition of all or a portion of our business to another company; and
                                            </li>
                                            <li>
                                                with your consent or direction to do so.
                                            </li>
                                        </ul>

                                        <p>
                                            We may also share aggregated or anonymized information that does not directly identify you.
                                        </p>
                                    </div>

                                    <h3 className="f_p f_size_22 f_500 t_color3">SECURITY OF YOUR PERSONAL INFORMATION</h3>
                                    <div className="card-body">
                                        Everpay takes measures to help protect your personal information in an effort to prevent loss, misuse and unauthorized access, disclosure, alteration and destruction.
                                    </div>


                                    <h3 className="f_p f_size_22 f_500 t_color3">YOUR INFORMATION CHOICES AND CHANGES</h3>
                                    <div className="card-body">
                                        You may opt out of receiving promotional emails from Everpay by following the instructions in those emails or by emailing us at . If you opt out, we may still send you non-promotional emails, such as emails about your accounts or our ongoing business relations. In addition, you can review and edit your personal information at any time by logging into your account.
                                    </div>

                                    <h3 className="f_p f_size_22 f_500 t_color3">CHANGES TO THIS POLICY</h3>
                                    <div className="card-body">
                                        Everpay may change this Policy from time to time. If we make any changes to the Policy, we will change the "Last Updated" date above.
                                    </div>

                                    <h3 className="f_p f_size_22 f_500 t_color3">QUESTIONS</h3>
                                    <div className="card-body">
                                        If you have any questions about this Policy, please contact us at <a href="mailto:support@everpayinc.com">support@everpayinc.com</a>.
                                    </div>





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
    }
}
export default PolicyContent;
