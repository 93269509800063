const ServiceData = {
    STitle: 'You SaasLand wherever your business agency',
    Service: [
        {
            id : 1,
            shape : 'icon_shape1.png',
            icon : 'ti-panel',
            text : 'Export Presets',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 2,
            shape : 'icon_shape2.png',
            icon : 'ti-layout-grid2',
            text : 'Grid and Guides',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 3,
            shape : 'icon_shape3.png',
            icon : 'ti-gallery',
            text : 'Prototyping',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 4,
            shape : 'icon_shape4.png',
            icon : 'ti-fullscreen',
            text : 'Pixel Precision',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        }
    ],
    agtext1: 'POS devices with the features and functionality you need to accept payments',
    agtext2: 'Have access to an extensive global network of alternative payments.',
    agtext3: 'Accept cryptocurrency directly in your retail store with phones or tablets.',
    pServiceTitle: 'Our Services',
    pServiceDetails: 'Why I say old chap that is, spiffing jolly good a load of old tosh spend a penny tosser arse over.!',
    PaymentService: [
        {
            id: 1,
            image: 'icon4.png',
            title: 'Safer',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 2,
            image: 'icon7.png',
            title: 'Flexible',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 3,
            image: 'icon5.png',
            title: 'Convinient',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 4,
            image: 'icon8.png',
            title: 'Protected',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 5,
            image: 'icon6.png',
            title: 'World wide',
            description: 'Well at public scho cheeky bugger grub burke.'
        }
    ],
    SubscriptionCommerce: [
        {
            id: '1',
            HRtitles : 'Minimize declines and maximize revenue',
            HRdescription: 'Set up logic in your account settings to automatically retry failed payments. Keep your business on the right track and make your customers stay longer with your brand.',
            Hicon: 'panel',
            rclass : 'pr_70',
            iclass: 'icon_one',
        },
        {
            id: '2',
            HRtitles : 'Offer flexible, multiple plans',
            HRdescription: 'Create as many flexible subscription plans as you want. Offer a variety of billing cycles or pricing tiers in order to provide the best choice for your customers.',
            Hicon: 'layout-grid2',
            rclass : 'pl_50 pr_20',
            iclass: 'icon_two',
        },
        {
            id: '3',
            HRtitles : 'Upgrade and downgrade subscriptions',
            HRdescription: 'Allow your customers to upgrade or downgrade their plans, and change their billing cycles with ease. Offer subscription payments with great flexibility and customer experience.',
            Hicon: 'fullscreen',
            rclass : 'pl_70',
            iclass: 'icon_three',
        },
        {
            id: '4',
            HRtitles : 'Recurring billing for SaaS',
            HRdescription: 'SaaS can create multiple subscription plans to meet customer needs and streamline their cash flow. See how easy is to set up free trials and make upgrades and downgrades. B2B SaaS can assign multiple subscriptions to a single customer.',
            Hicon: 'vector',
            rclass : 'pr_70',
            iclass: 'icon_four',
        },
        {
            id: '5',
            HRtitles : 'Payments for online dating',
            HRdescription: 'Add recurring payments to your dating site so your customers can start a subscription. Optimize the end-user experience to get the best results. Dating businesses can increase conversions by up to 20%.',
            Hicon: 'cloud-down',
            rclass : 'pl_50 pr_20',
            iclass: 'icon_five',
        },
        {
            id: '6',
            HRtitles : 'Subscriptions for digital content',
            HRdescription: 'Use API to create flexible subscriptions depending on your business needs. Easily define billing periods, pricing levels and turning on automatic upgrades.',
            Hicon: 'bolt',
            rclass : 'pl_70',
            iclass: 'icon_six',
        }
    ],
    HRService: [
        {
            id: '1',
            HRtitles : 'Export Presets',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'panel',
            rclass : 'pr_70',
            iclass: 'icon_one',
        },
        {
            id: '2',
            HRtitles : 'Grid and Guides',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'layout-grid2',
            rclass : 'pl_50 pr_20',
            iclass: 'icon_two',
        },
        {
            id: '3',
            HRtitles : 'Pixel Precision',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'fullscreen',
            rclass : 'pl_70',
            iclass: 'icon_three',
        },
        {
            id: '4',
            HRtitles : 'Vector Editing',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'vector',
            rclass : 'pr_70',
            iclass: 'icon_four',
        },
        {
            id: '5',
            HRtitles : 'Cloud Service',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'cloud-down',
            rclass : 'pl_50 pr_20',
            iclass: 'icon_five',
        },
        {
            id: '6',
            HRtitles : 'Iterate at Speed',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'bolt',
            rclass : 'pl_70',
            iclass: 'icon_six',
        }
    ],
    rpost:[
        {
            id: '01',
            image: 'blog-grid/post_1.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 1, 2019'
        },
        {
            id: '02',
            image: 'blog-grid/post_2.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 10, 2019'
        },
        {
            id: '03',
            image: 'blog-grid/post_3.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'jan 10, 2019'
        },
        {
            id: '04',
            image: 'blog-grid/post_4.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 1, 2019'
        }
    ],
    Blist: [
        {
            id: '01',
            image: 'blog-grid/blog_list1.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '02',
            image: 'blog-grid/blog_list2.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'June'
        },
        {
            id: '03',
            image: 'blog-grid/blog_list3.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '04',
            image: 'blog-grid/blog_list4.jpg',
            btitle: 'Bloke cracking goal the full monty get stuffed mate posh.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '01',
            Month: 'July'
        },
    ],
    work: [
        {
            id: '01',
            icon: 'ti-settings one',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '02',
            icon: 'ti-heart-broken two',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '03',
            icon: 'ti-target  three',
            title: 'Live page builder',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        }
    ]
}
export default ServiceData;