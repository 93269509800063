import React from 'react';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import BecomeAPartnerForm from '../components/BecomeAPartner/BecomeAPartnerForm'
import Breadcrumb from '../components/Breadcrumb';

const BecomeAPartner = () => {
	return (
		<div>
			<Breadcrumb
				breadcrumbClass="breadcrumb_area"
				imgName="breadcrumb/banner_bg.png"
				Ptitle="Become a Partner"
				Pdescription="" />
			<BecomeAPartnerForm/>
			<FooterTwo FooterData={FooterData} />
		</div>
	)
}
export default BecomeAPartner;
