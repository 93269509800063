import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal'
class PaymentBanner extends Component {
    render() {
        return (
            <section className="payment_banner_area">
                <div className="shape one"></div>
                <div className="shape two"></div>
                <div className="container">
          <Reveal effect="fadeInLeft" duration={500}>
         <div className="payment_banner_content wow fadeInLeft" data-wow-delay="0.4s">
                            <h1 className="f_p f_700 f_size_50 w_color">A Simpler Way To Accept International Payments</h1>
                            <p className="w_color f_size_22">Reduce costs, scale easily and improve security with our complete payment processing solutions. Now your business can accept all card brand types, multiple alternative payment methods, real-time payments, 24/7 check, deposit and invoice processing services to deliver faster access to your operational funds.</p>
                            <div className="action_btn d-flex align-items-center mt_60">
 <a href="/get-quote/" className="agency_banner_btn_two mr-4">Get A Free Quote<i className="ti-arrow-right"></i></a>

   <a href="https://gate.everpayinc.com/register" className="btn_hover agency_banner_btn">Sign Up for Free</a>
  
                            </div>
                        </div>
                    </Reveal>
                </div>
                <Reveal effect="fadeInLeft" duration={1200}>
             <div className="animation_img_two wow fadeInRight" data-wow-delay="0.5s">
                        <img src={require('../../img/home9/mac.png')} alt="" />
                    </div>
                </Reveal>
            
            </section>
        )
    }
}
export default PaymentBanner;
