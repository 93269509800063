import React, { Component } from 'react';
import { Link } from 'react-router-dom'
class IndustriesServedContent extends Component {
    render() {
        return (
            <section className="security_integration_area chat_integration_area">
                <div className="container mt_60 text-center">

                    <div className="text-center">
                        <h4>Get your business payment ready</h4>
                        <p>We have a payment solution for every type of business in every industry.</p>
                    </div>
                    <div className="col-md-12 col-lg-12 mt_100">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a href="/#" className="s_integration_item">
                                    <img src={require("./../../img/industries/retail.png")} alt="" />
                                    <h5>Retail</h5>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a href="/#" className="s_integration_item">
                                    <img src={require("./../../img/industries/restaurant.png")} alt="" />
                                    <h5>Restaurant</h5>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a href="/#" className="s_integration_item">
                                    <img src={require("./../../img/industries/trade.png")} alt="" />
                                    <h5>Trade</h5>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a href="/#" className="s_integration_item">
                                    <img src={require("./../../img/industries/professional-practice.png")} alt="" />
                                    <h5>Professional Practice</h5>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a href="/#" className="s_integration_item">
                                    <img src={require("./../../img/industries/not-for-profit.png")} alt="" />
                                    <h5>Not-For-Profit</h5>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a href="/#" className="s_integration_item">
                                    <img src={require("./../../img/industries/enterprise.png")} alt="" />
                                    <h5>Enterprise</h5>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <h4>Everpay Gives you business the Means to Succeed</h4>
                        <p>Business, today is more competitive than ever. Attracting customers means that you need to seize every possible advantage. We can help with payment solutions that drive sales, boost loyalty and let you focus on your priorities.</p>
                    </div>
                    <div className="text-center">
                        <h4>
                            Partnering for some Businness Success
                            </h4>
                        <p>
                            As an independent business, your decisions have an immediate impact.
                            Having the right payment solution is essential, and we’re prepared to help you make that choice.
                            Better yet, our options can easily integrate with your current POS system and software.
                        </p>
                    </div>
                    <div className="text-center">
                        <h3>
                            Get started today
                    </h3>
                        <p>If you have any questions, or are ready to get set up</p>
                        <Link className="btn btn-info" to={'/contact-us'}>Contact Us</Link>
                    </div>
                </div>
            </section >
        )
    }
}
export default IndustriesServedContent;
