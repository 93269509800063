import React from 'react';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Breadcrumb from '../components/Breadcrumb';
import CookieContent from '../components/Policy/CookieContent';

const Cookie = () => {
    return (
        <div>
            <Breadcrumb
                breadcrumbClass="breadcrumb_area"
                imgName="breadcrumb/banner_bg.png"
                Ptitle="Cookie preferences"
                Pdescription="" />
            <CookieContent>
            </CookieContent>
            <FooterTwo FooterData={FooterData} />
        </div>
    )
}
export default Cookie;