import React, { Component } from 'react';
// import SeoTitle from '../Title/SeoTitle';

class JobDetailsContent extends Component {
    render() {
        return (
            <section>
                <div className="container mt_40">
                    <div className="jobs-detail">
                        <div className="card p_10">
                            <h2>Credit Analyst</h2>
                            <p className="job-sub-heading">Enterprise Risk Management / Gestion du risque  Etobicoke, ON</p>
                            <button className="btn btn-info btn-apply-now">Apply</button>
                            <hr />
                            <h4>Description</h4>
                            <div className="job-desc">
                                <p className="job-position">
                                    <strong>
                                        Position at Everpay Corporation
                                    </strong>
                                </p>
                                <p>The Credit Analyst – Independent Business (IB) performs various forms of credit-based analysis on prospective and existing merchants and provides customer service on credit related issues.</p>
                            </div>
                            <div className="job-accountablity">
                                <p><strong>You will be accountable for:</strong></p>
                                <ul>
                                    <li>Applying appropriate underwriting criteria to new or existing merchant applications in accordance with established credit policy to mitigate financial risk.</li>
                                    <li>Post-reviewing of merchants in need of a line increase or flagged by fraud process specifically in processing volume level or average transaction size.</li>
                                    <li>Performing a credit risk assessment of existing high-risk merchants ensuring compliance with credit, fraud, card association regulation and legal matters.</li>
                                    <li>Assisting with customer calls for complex merchant set-ups requiring explanation of specific credit policies and decisions related to those policies.</li>
                                    <li>Collaborating with Moneris Fraud Group to monitor the activity of selected High Risk Merchants.</li>
                                </ul>
                            </div>

                            <div className="job-exp">
                                <p><strong>Your experience includes:</strong></p>
                                <ul>
                                    <li>Bachelors’ degree in Business, Accounting, Finance or a related discipline.</li>
                                    <li>Minimum 1-year experience reviewing personal credit bureaus or commercial credit bureaus, credit adjudication, credit assessment write-up.</li>
                                    <li>Experience with financial statement analysis, or a strong understanding of business financial statements is required for the role.</li>
                                    <li>Proficiency with MS Office applications</li>
                                    <li>Strong interpersonal and communication skills (written and verbal).</li>
                                </ul>
                            </div>
                            <div className="action">
                                <div className="text-center">
                                    <button className="btn btn-info">Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- tab-content --> */}
                </div>
            </section>)
    }
}

export default JobDetailsContent;
