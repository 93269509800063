import React, {Component} from 'react';
import SeoTitle from '../../Title/SeoTitle';
import { Fade } from 'react-reveal';

class CoinPaymentSolutions extends Component{
    render(){
        return(
            <div className="container">
               <div className="mt_100 mb_60">
                <SeoTitle
                    Title='Accept crypto payments,
                    grow your business.'
                    TitleP='Many advantages, no drawbacks.' />
                </div>
                <div className="row seo_service_info mb_50">
                    <Fade bottom duration={500}>
                        <div className="col-lg-4 col-md-6">
                            <div className="seo_service_item text-center">
              <img src={require('../../../img/cp-solutions/chargeback.png')} alt="" />
                                <a href=".#">
                                    <h4>Chargeback Proof</h4>
                                </a>
                                <p>No chargebacks with cryptocurrencies.</p>
                            </div>

                        </div>
                    </Fade>
                    <Fade bottom duration={700} >
                        <div className="col-lg-4 col-md-6">
                            <div className="seo_service_item text-center">
                                <img src={require('../../../img/cp-solutions/norisk.png')} alt="" />
                                <a href=".#">
                                    <h4>Secure</h4>
                                </a>
                                <p>Protected by real-time exchange rates.</p>
                            </div>
                        </div>
                    </Fade>
                    <Fade bottom duration={1000}>
                        <div className="col-lg-4 col-md-6">
                            <div className="seo_service_item text-center">
                     <img src={require('../../../img/cp-solutions/privacy.png')} alt="" />
                                <a href=".#">
                                    <h4>Full privacy</h4>
                                </a>
                                <p>Fully compliant Data Protection Acts.</p>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        )
    }
}

export default CoinPaymentSolutions;