import React from 'react';
import SeoTitle from '../Title/SeoTitle';
import { Reveal } from 'react-reveal';
import Teamitem from '../Team/Teamitem';
import Sectitle from '../Title/Sectitle';
// import Breadcrumb from '../Breadcrumb';
// import Teams from './../Team/Team';
// import Bankers from './../Team/Bankers';

const AboutContent = () => {
    return (
        <section className="seo_service_area sec_pad">
            <div className="container">
                <SeoTitle Title='Intro' TitleP={`We're obsessed with all forms of payments infrastructure, including blockchain, Fintech, neo banking, and advisory services. <br><br> Everpay was created to increase the global economy (increase GDP) by enabling new commerce in emerging and under developed marrkets. Our mechanisms and facility to do that is payments. Everything else we do depends on how it fits into the vision for Everpay.`} />
                {/* Founders */}
               <section className="experts_team_area sec_pad" id="team">
                    <div className="container">
                        <Sectitle sClass="sec_title text-center mb_70" Title="Founders" tClass="t_color3" TitleP="Pictured above from left to right" />
                        <Reveal bottom cascade>
                            <div className="row">
                                <div className="col-lg-3 col-sm-6">
                                    <Teamitem memberN="Richard Rowe" memberd="Founder & CTO" />
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <Teamitem memberN="Martin Bishop" memberd="Chief Revenue Officer" />
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <Teamitem memberN="Yuval Binstoc" memberd="Chief Compliance Officer" />
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <Teamitem memberN="Sunny Young" memberd="Chief Operating Officer " />
                                </div>
                            </div>
                        </Reveal>
                    </div>
                </section>
                {/* Founders section end */}
            
            </div>
        </section>
    )
}

export default AboutContent;
