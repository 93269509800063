import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import DirectDebitsSection from '../components/Pricing/DirectDebitsSection'

const DirectDebits = () => {
    return(
        <div>
            <Breadcrumb
                breadcrumbClass="breadcrumb_area"
                imgName="breadcrumb/banner_bg.png"
                Ptitle="Direct Debits"
                Pdescription="Direct Debit can be used for most payments but it’s most often used to pay"/>
            <DirectDebitsSection/>
            <FooterTwo fClass="pt_150" FooterData={FooterData}/>
        </div>
    )
}
export default DirectDebits;