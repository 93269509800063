import React from 'react';
import SecurityFeaturesItem from './SecurityFeaturesItem';

const SecurityFeatures =()=>{
    return(
        <section className="h_security_area">
            <div className="container">
                <div className="hosting_title security_title text-center">
 <h2 className="wow fadeInUp" data-wow-delay="0.2s"><span className="f_size_50">What we do</span>Enable businesses for fast commerce </h2>
                </div>
                <div className="row">
                    <SecurityFeaturesItem itemClass="pr_70" image="security_1.png" Title="Powering digital payments for the 21st century" pText="Easy cross border commerce, with one simple integration, your business can easily accept payments in 50+ currencies and in over 50 countries."/>
                    <SecurityFeaturesItem itemClass="pl_70" image="security_2.png" Title="ACH, cross-border and direct-debit solutions" pText="We provide cross-border, real-time, immediate payments capabilities."/>
                    <SecurityFeaturesItem itemClass="pr_70" image="security_3.png" Title="Accept crypto anywhere and everywhere" pText="With our safe and easy to use solution, we enable your business to accept crypto payments and quickly increase your revenue"/>
                    <SecurityFeaturesItem itemClass="pl_70" image="security_4.png" Title="We combine the right fraud protection services" pText="Using award-winning artificial intelligence-powered suite of enterprise tools to manage risk and combat the toughest types of fraud."/>
                </div>
  <div className="row">
  <div className="col-md-12 text-center mt_40 mb_30">
<a href="/card-processing" class="price_btn btn_hover mt_50">Learn how</a>
</div>
</div>
            </div>
        </section>
    )
}
export default SecurityFeatures;