import React from 'react';
import AboutContent from './../components/About/AboutContent'
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Breadcrumb from './../components/Breadcrumb'
const About = () => {
    return(
        <div>
            <Breadcrumb
                breadcrumbClass="breadcrumb_area"
                imgName="breadcrumb/banner_bg.png"
                Ptitle="About"
                Pdescription={`
                    <p class="f_400 w_color f_size_16 l_height26"><strong>Founded: </strong> November 2009</p>
                    <p class="f_400 w_color f_size_16 l_height26"><strong>Located: </strong>  Montreal, CA</p>
                    <p class="f_400 w_color f_size_16 l_height26"><strong>Team: </strong>  750 payment connections and growing</p>
                    <p class="f_400 w_color f_size_16 l_height26"><strong>Purpose: </strong>  Enabling new methods of e-commerce</p>`}/> 
            <AboutContent/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default About;
