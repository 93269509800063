import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import HRService from '../components/Service/HRService';
//import ServiceSubscribe from '../components/ServiceSubscribe';
import FooterTwo from '../components/Footer/FooterTwo';
import ServiceData from '../components/Service/ServiceData';
import HostingActionTwo from '../components/HostingActionTwo';
import FooterData from '../components/Footer/FooterData';
import SubscriptionCommerceContent from '../components/SubscriptionCommerce/SubscriptionCommerceContent';

const SubscriptionCommerce = () => {
    return(
        <div>
  <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Subscription Commerce" Pdescription=""/>
            <SubscriptionCommerceContent/>
            <HRService ServiceData={ServiceData}/>
            <HostingActionTwo/>
            <FooterTwo fClass="pt_150" FooterData={FooterData}/>
        </div>
    )
}
export default SubscriptionCommerce;
