import React, { Component } from 'react';
import SeoTitle from './../../components/Title/SeoTitle'

class DirectDebitsSectionContent extends Component {
    render() {
        return (
            <section>
                <SeoTitle Title="A Direct Debit is an instruction from you to your bank." TitleP={`
                    A Direct Debit authorises someone to collect payments from your account when they are due. You give this authorisation by completing a  form – this can be a paper form or a web page that you complete online. Once authorised, the organisation can automatically take payments from you (provided that they comply with the scheme rules).
                `} />

                <SeoTitle Title="Direct Debit can be used for nearly everything" TitleP={`
                    <p>Direct Debit can be used for most payments but it’s most often used to pay:</p>
                    <p><strong>Regular bills for variable amounts</strong> - With Direct Debit you know all your important bills will be paid on time each month. In 2011 2.4bn payments were made by Direct Debit for utility bills and council taxes.</p>
                    <p><strong>Fixed subscriptions or memberships</strong> - Direct Debit is the safest and easiest way to make  like magazine subscriptions or gym memberships.
                    <p><strong>Paying on account</strong> – Some organisations will provide Direct Debit as an option for spreading your costs or paying on account.</p>
                `} />
                <SeoTitle Title="Direct Debit can be used for one-off payments too." TitleP={`
                    <p>While Direct Debit is usually associated with regular and recurring payments, it can also be used for one-off payments. Direct Debit should not be used for one-off payments where instant transfer of funds is needed, like e-commerce. However, if the organisation you're paying doesn't need the money immediately, Direct Debit can be a great option.</p>
                `} />

                <SeoTitle Title="What are the important Direct Debit scheme rules that I should know?" TitleP={`
                    <p><strong>Advance notice</strong> - You must be told the amount and date of each payment in advance.</p>
                    <p><strong>Direct Debit guarantee</strong> - You are entitled to an immediate refund for any payments that shouldn’t have been taken.</p>
                `} />

            </section>

        )
    }
}
export default DirectDebitsSectionContent;
