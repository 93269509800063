import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import ServiceDetails from '../components/Service/ServiceDetails';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const ServiceDetail = () => {
    return(
        <div>
            {/* <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto" } alt="logo" /> */}
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="banner_bg.png" alt="banner" Ptitle="Service Overview" Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"/>
            <ServiceDetails/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default ServiceDetail;