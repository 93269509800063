import React , {Component} from 'react';
//import Sectitle from './Title/Sectitle';

class ContactsMap extends Component {
    constructor(){
        super();
        this.state= {
            Map:[
                {
                    id: 1,
                    countryName: 'California, USA',
                },
                {
                    id: 2,
                    countryName: 'Toronto, Canada',
                },
                {
                    id: 3,
                    countryName: 'London, United Kingdom',
                },
                {
                    id: 4,
                    countryName: 'Bangalore, India',
                },
                {
                    id: 5,
                    countryName: 'St-Petersburg, Russia',
                },
                {
                    id: 6,
                    countryName: 'Merida, Mexico',
                },
                {
                    id: 7,
                    countryName: 'Ville De, Mauritius',
                },
                {
                    id: 8,
                    countryName: 'Queensland, Australia',
                }
            ]
        }
    }
    render(){
        return(
            <section className="contacts_map_area">
                <div className="container mb_40">
<div className="hosting_title security_title text-center mt_90">
 <h2 className="wow fadeInUp mb_70 mt_90" data-wow-delay="0.2s"><span className="f_size_50">Our Locations</span></h2>
                </div>

                    <div className="h_map mt_70 mb_70">
                        <ul className="list-unstyled">
                            {
                                this.state.Map.map(item=>(
              <li className="wow fadeIn" data-wow-delay="0.1s" key={item.id}>
                   <div className="place_name wow fadeInUp" data-wow-delay="0.2s">{item.countryName}</div>
                    <div className="round"><div className="dot"></div></div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>

                </div>
            </section>
        )
    }
}
export default ContactsMap;