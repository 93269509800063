import React from 'react';
//import Sectitle from "../Title/Sectitle";

const CloudFeatures =()=>{
    return(
        <section className="design_developers_area sec_pad">
            <div className="container">
<div className="hosting_title security_title text-center mt_20">
 <h2 className="wow fadeInUp mb_70 mt_20" data-wow-delay="0.2s"><span className="f_size_50">We do developers right</span>The right integration resources</h2>
                </div>

                <div className="row">
                    <div className="col-lg-7 col-md-7">
                        <div className="design_img wow fadeInRight" data-wow-delay="0.2s">
         <img className="img-fluid" src={require("../../img/home5/design1.png")} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 d-flex align-items-center">
      <div className="design_img_two wow fadeInRight" data-wow-delay="0.4s">
     <img className="img-fluid"  src={require("../../img/home5/design2.png")} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="text-center wow fadeInUp" data-wow-delay="0.4s">
           <a href="https://docs.everpayinc.com" className="btn_hover price_btn mt_70">API Documentation</a>
                </div>
            </div>
        </section>
    )
}
export default CloudFeatures;