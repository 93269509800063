import React from 'react';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import JobDetailsContent from '../components/Jobs/JobDetailsContent'

const JobDetails = () => {
	return (
		<div className="mt_100">
			<JobDetailsContent/>
			<FooterTwo FooterData={FooterData} />
		</div>
	)
}
export default JobDetails;
