import React, { Component } from 'react';
import ReportingSecurityIssues from './ReportingSecurityIssues';
class SecurityContent extends Component {
    render() {
        return (
            <section className="faq_area bg_color mt_40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ReportingSecurityIssues />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default SecurityContent;
