import React from 'react';
// import { Link } from 'react-router-dom';

const BecomeAPartnerForm = () => {
    return (
        <section className="sign_in_area bg_color sec_pad">
            <div className="container">
                <div className="sign_info">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="sign_info_content">
                                <h3 className="f_p f_600 f_size_24 t_color3 mb_40">Want to Become A Partner?</h3>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="login_info">
                                {/* <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Sign Up</h2> */}
                                <form action="#" className="login-form sign-in-form">
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Name</label>
                                        <input type="text" placeholder="Name" />
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Company Name</label>
                                        <input type="text" placeholder="Company Name" />
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Phone No</label>
                                        <input type="text" placeholder="Phone No" />
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Company Url</label>
                                        <input type="text" placeholder="Company Url" />
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Why you want to us</label>
                                        <textarea placeholder="Why you want to us?" />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <button type="submit" className="btn_three">Join Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default BecomeAPartnerForm;