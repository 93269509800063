import React, { Component } from 'react';
// import Slider from 'react-slick';
import SeoTitle from '../Title/SeoTitle';
import { Fade } from 'react-reveal';

class HowDoesItWork extends Component {
    render() {
        return (
            <div>
                <SeoTitle Title='How does it work?' TitleP='Everpay supports all major card brands from Visa and MasterCard to Discover and American Express. Funds are captured in U.S. currency and available for immediate access in your escrow balance.' />
                <div className="row seo_service_info">
                    <Fade bottom duration={500}>
                        <div className="col-lg-4 col-md-6">
                            <div className="seo_service_item">
                                <img src={require('../../img/seo/icon1.png')} alt="" />
                                <a href=".#">
                                    <h4>Step 1</h4>
                                </a>
                                <p>Charge your customer’s debit or credit card</p>
                                <a href=".#"><i className="arrow_right"></i></a>
                            </div>

                        </div>
                    </Fade>
                    <Fade bottom duration={700} >
                        <div className="col-lg-4 col-md-6">
                            <div className="seo_service_item">
                                <img src={require('../../img/seo/icon2.png')} alt="" />
                                <a href=".#">
                                    <h4>Step 2</h4>
                                </a>
                                <p>Funds are available immediately in your escrow balance</p>
                                <a href=".#"><i className="arrow_right"></i></a>
                            </div>
                        </div>
                    </Fade>
                    <Fade bottom duration={1000}>
                        <div className="col-lg-4 col-md-6">
                            <div className="seo_service_item">
                                <img src={require('../../img/seo/icon5.png')} alt="" />
                                <a href=".#">
                                    <h4>Step 3</h4>
                                </a>
                                <p>Collect your funds by paying out to your bank account</p>
                                <a href=".#"><i className="arrow_right"></i></a>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        )
    }
}
export default HowDoesItWork;


