import React, { Component } from 'react';
import Slider from 'react-slick'
import { Link } from 'react-router-dom';

class Pserviceslider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '100px',
      responsive: [
        {
          breakpoint: 1250,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '100px',
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: '0px',
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: '0px',
          }
        }
      ]
    };
    return (
      <Slider className="service_carousel" {...settings}>
        <div className="service_item">
          <Link to="/services/service-details">
            <div className="icon s_icon_one"><i className="ti-check"></i></div>
            <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Business Financing</h4>
            <p>An expert in their industry, Thinking Capital works with you closely offering you tailored ideas to grow your business and quick access to the capital you need. Receive a no obligation quote in minutes, and funding in as little as 3-5 business days.</p>
            <img className="float-right" src={require('../../../img/services/business-financing.png')} alt="" />
          </Link>
        </div>
        <div className="service_item">
          <Link to="/services/service-details">
            <div className="icon s_icon_two"><i className="ti-location-arrow"></i></div>
            <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Gift Card Offerings</h4>
            <p>Whether your customer wants the perfect gift or a little extra incentive, Everpay has a solution that works for any sized business.</p>
            <img className="float-right" src={require('../../../img/services/gift-card-offerings.png')} alt="" />
          </Link>
        </div>
        <div className="service_item">
          <Link to="/services/service-details">
            <div className="icon s_icon_three"><i className="ti-search"></i></div>
            <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Loyalty</h4>
            <p>The Everpay Loyalty Program is designed to give customers the best options and keep them coming back. It drives incremental sales, attracts and retains customers, and provides unique insights. It’s a loyalty program that’s practical and accessible for businesses of all sizes, including yours.</p>
            <img className="float-right" src={require('../../../img/services/loyalty.png')} alt="" />
          </Link>
        </div>
        <div className="service_item">
          <Link to="/services/service-details">
            <div className="icon s_icon_four"><i className="ti-stats-up"></i></div>
            <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Everpay Field Services</h4>
            <p>At Everpay Field Services, our products and offerings are designed to help streamline merchants’ point-of-sales operations. We make sure your payment and non-payment hardware and software are installed and maintained to your specifications and needs, so you can focus on what’s most important: your business.</p>
            <img className="float-right" src={require('../../../img/services/everpay-field-service.png')} alt="" />
          </Link>
        </div>
      </Slider>
    )
  }
}
export default Pserviceslider;